<template>
  <div>
    <template>
      <div>
        <!-- 绑定手机号 -->
        <van-popup
          v-model="localShow"
          position="bottom"
          custom-style="height: 50%;"
          :close-on-click-overlay="false"
          :closeable="true"
          @click-close-icon="onClose"
        >
          <div class="bindBox">
            <!-- <div class="tit">{{ parkName?parkName:'停车缴费' }}</div> -->
            <!-- <div class="tit" v-if="checkOldPhoneLocal">验证已绑定手机号</div> -->
            <!-- <div class="tit" v-else>绑定手机号</div> -->
            <div class="tit">更改手机号</div>
            <van-divider />
            <div class="content">
              <van-field
                v-model="form.phone"
                center
                clearable
                label=""
                type="number"
                placeholder="请输入手机号"
              >
                <!-- <template #button>
                  <van-button
                    class="sendCodeBtn"
                    size="small"
                    type="primary"
                    @click="senPhoneCode"
                    :disabled="countDownFlag"
                  >
                    <span v-if="!countDownFlag">发送验证码</span>
                    <span class="countDownBox" v-else>
                      重新获取(<van-count-down
                        :time="countDownTime"
                        format="ss"
                        @finish="countDownFinish"
                      />)
                    </span>
                  </van-button>
                </template> -->
              </van-field>
              <!-- <van-field
                v-model="form.phoneCode"
                center
                clearable
                label=""
                placeholder="请输入验证码"
              >
              </van-field> -->
            </div>
            <van-divider />
            <!-- <div class="content">
              <van-field
                v-model="form.phone"
                center
                clearable
                label=""
                placeholder="请输入手机号"
              >

              </van-field>

            </div> -->

            <div class="btns">
              <van-button
                class="confirmlBtn"
                :loading="btnLoading"
                loading-type="spinner"
                loading-text="请稍后..."
                type="primary"
                color="##4470FC"
                @click="bindPhone"
                >{{ checkOldPhoneLocal ? "验证" : "绑定" }}</van-button
              >
            </div>
          </div>
        </van-popup>
      </div>
    </template>
  </div>
</template>
<script>
import { Toast } from 'vant'
import { mapState, mapGetters } from 'vuex'
import * as util from '../api/util'
import * as constant from '../api/constant'
import * as api from '../api/api'
import * as common from '../api/common'
import axios from 'axios'

export default {
  name: 'BindPhone',
  props: ['show', 'checkOldPhone'],
  data () {
    return {
      localShow: this.show,
      checkOldPhoneLocal: this.checkOldPhone,
      form: {
        phone: '',
        phoneCode: ''
      },
      btnLoading: false,
      countDownTime: 60 * 1000,
      countDownFlag: false

    }
  },
  mounted () {

  },
  computed: {
    ...mapState({
      parkName: state => state.parkName
    }),
    ...mapGetters(['sid', 'openID'])

  },
  watch: {
    show (newVal) {
      // 监听 prop 的变化，并将其赋值给本地的 data 属性
      this.localShow = newVal
    },
    checkOldPhone (newVal) {
      // 监听 prop 的变化，并将其赋值给本地的 data 属性
      this.checkOldPhoneLocal = newVal
    }
  },
  methods: {
    async getInfo () {
      try {
        const { data } = await axios({
          url: common.cloudPayUrl + '/pub/' + api.urlList.pay.bill.park_plate.get_self_info,
          method: 'POST',
          data: {
            sid: this.sid || this.$route.query.sid,
            // sid: this.sidLocal,
            openid: this.openID
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        console.log('getInfo res', data)

        if (data.code === 10000 && data.info) {
          // this.authPhoneLocal = data.phone_number
          this.$store.commit(constant.keyPhoneVuex, data.info.phone_number)
        } else {
          Toast.fail('查询出错', data.msg)
        }
      } catch (e) {
        this.$toast('查询异常', e)
      }
    },
    cancelBtn () {
      this.$emit('get-cancel', 2)
    },
    confirmlBtn () {
      this.$emit('get-success', 1)
    },
    onClose () {
      this.$emit('get-on-close')
    },
    countDownFinish () {
      // 倒计时结束
      this.countDownFlag = false
    },
    senPhoneCode () {
      if (!this.form.phone) {
        Toast.fail('请输入手机号')
        return
      }
      if (!util.isValidPhoneNumber(this.form.phone)) {
        Toast.fail('请输入正确的11位手机号')
        return
      }
      this.countDownFlag = true
      setTimeout(() => {
        // 测试验证码
        const phoneCode = '123'
        this.form.phoneCode = phoneCode
      }, 2000)
    },
    async bindPhone (phone, code) {
      if (!this.form.phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!util.isValidPhoneNumber(this.form.phone)) {
        this.$toast('请输入正确的11位手机号')
        return
      }
      this.btnLoading = true
      // if (this.checkOldPhoneLocal) {
      //   // 先验证原手机号
      //   setTimeout(() => {
      //     const code = true
      //     console.log('验证原手机号成功', code)
      //     if (code) {
      //       this.$emit('get-on-check-old')
      //       this.btnLoading = false
      //     } else {
      //       this.Toast('验证原手机号失败')
      //     }
      //   }, 1000)
      // } else {
      // 直接绑定
      console.log('正在绑定手机号', this.form)
      try {
        const { data } = await axios({
          url: common.cloudPayUrl + '/pub/' + api.urlList.pay.bill.park_plate.bind_phone_number,
          method: 'POST',
          data: {
            sid: this.sid || this.$route.query.sid,
            // sid: this.sidLocal,
            openid: this.openID,
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
            version: 'v2',
            // access_token: 'CA502D23-BB70-0001-55FA-1B501C501A6B',
            // app_key: '666a659e-244d-46b1-a8cc-3c5190c3823a',
            phone_number: this.form.phone
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        console.log('openid res', data)

        if (data.code === 10000) {
          Toast.success('手机号码绑定成功')
          this.$emit('get-on-close')
          this.btnLoading = false
          this.getInfo()
        } else {
          Toast.fail('绑定出错', data.msg)
          this.btnLoading = false
        }
      } catch (e) {
        this.$toast('绑定异常', e)
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.bindBox {
  height: 2.4rem;
  padding: 0.32rem 0.16rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.tit {
  color: #000;
  font-size: 0.18rem;
  margin-bottom: 0.32rem;
  margin-top: 0.05rem;
}

.content {
  color: #000;
  font-size: 0.14rem;
  line-height: 0.2rem;
  .sendCodeBtn {
    width: 1.25rem;
    display: flex;
    /deep/ .van-button__content {
      width: 100%;
      display: flex;
    }
  }
}

.btns {
  /* margin-bottom: 80rpx; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0.16rem;
}

.btns .btn {
  margin-top: 0.16rem;
  /* margin-bottom: 80rpx; */
  /* margin-left: 50rpx; */
}

.cancelBtn,
.confirmlBtn {
  width: 3.52rem;
  height: 0.4rem;
  /* border: 1rpx solid; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.16rem;
}

.confirmlBtn {
  // background-color: #58BE6A;
  // color: #fff;
  // font-size: 0.9375rem;
  // border: 1px solid #58BE6A;
}

.cancelBtn {
  background-color: #fff;
  color: #4470fc;
  font-size: 0.16rem;
  border: 0.01rem solid #4470fc;
}
.countDownBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
/deep/ .van-count-down {
  color: #fff;
}
.van-divider {
  margin: 0.0533rem 0 !important;
}
</style>
